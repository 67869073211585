import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@bit/azheng.joshua-tree.button"
import ImageMeta from "../components/ImageMeta"

const SecondChance = () => {
  const hasForm = false

  return (
    <Layout>
      <SEO
        lang="en"
        title="Second Chance Program at Oral Surgery Group"
        description="It is time for a brand new, healthy, beautiful smile."
      />

      <div className="sc-program">
        <div className="sc__hero">
          <div className="sc__hero--desktop">
            <img
              className="sc__hero-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1659042618/Programs/second-chance-desktop-hero-image.jpg"
              alt="program banner"
            />
          </div>
          <div className="sc__hero--mobile">
            <img
              className="sc__hero-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1659042617/Programs/second-chance-mobile-hero-image.jpg"
              alt="program banner"
            />
          </div>
        </div>

        <div className="sc__section">
          <div className="">
            <div className="sc__about-grid--content">
              <p className="sc__subheading">
                A Second Chance for a Healthy Smile{" "}
              </p>
              <br />
              <p>
                Oral Surgery Group is proud to announce its 2022 Second Chance
                recipient. This life-changing program provides a deserving
                recipient living in or near New Brunswick, East Brunswick, or
                Middlesex, NJ, with a complete smile makeover free of charge.
              </p>
              <p>
                Nicole, 50, lives in Plainsboro and works as an overnight
                stocker. She is missing all but three of her bottom teeth, and
                even those need to be removed. Nicole wrote about how her
                self-esteem has been destroyed and how difficult it is to talk
                to people because she’s self-conscious about her smile. When she
                rarely does talk to people, she tries to avoid looking at them
                or deflects their attention away from her so they won’t look at
                her. It’s not who she was or who she wants to be; she used to be
                outgoing. She wants to be able to eat her favorite foods again,
                and she wants to have her confidence back. Overall, she hopes
                that a new smile can help her go back to school and get a job in
                a professional environment, specifically something where she can
                work with animals, and where she can confidently speak with
                others.
              </p>
              <br />
              <p className="sc__subheading">Full-Arch Restoration</p>
              <br />
              <p>
                <Link
                  to="/procedure/full-arch-restoration-new-brunswick-nj/"
                  title="Learn more about full-arch restoration"
                >
                  Full-arch restoration
                </Link>{" "}
                is a healthy and effective way to replace many missing teeth.
                The procedure works by securing a full prosthesis in the mouth
                using as few as four dental implants. The final set of teeth
                will look just like natural teeth, so patients can eat, speak,
                and smile with confidence.
              </p>
            </div>
            {/* <div className="sc__about-grid--info">
                <div className="sc__brackets sc__brackets--desktop">
                  <div>
                    <p className="sc__subheading">How To Apply</p>
                    <ul>
                      <li>Complete the short application.</li>
                      <li>Upload photos of your smile and teeth.</li>
                      <li>Submit your application by September 22, 2022.</li>
                    </ul>
                  </div>
                   <div>
                        <p className="sc__subheading">Treatment Partners:</p>
                        <ul>
                            <li>Restorative Partner: Dr. Rosa Mathai from <a href='https://www.westportaldentalcare.com/' title='Go to West Portal Dental Care Website' target="_blank">West Portal Dental Care</a></li>
                            <li>Dental Lab: </li>
                            <li>Implant Provider: </li>
                        </ul>
                    </div>  
                </div>
              </div> */}
          </div>
        </div>

        {hasForm && (
          <>
            {/* <div className="sc__apply-banner">
                <p>CLICK THE BUTTON BELOW TO FILL OUT THE FORM</p>
              </div> */}

            <div className="sc__section sc__form">
              {/* <iframe
                  className="seamlessdocsEmbededIframe"
                  src="https://secureform.seamlessdocs.com/f/6dd91f5ce33a8fe86c4d9fddb8867129?embedded=true"
                  width="100%"
                  height="700px"
                  frameborder="0"
                  allowtransparency="true"
                  sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
                ></iframe> */}

              {/* <a
                  className="standard-button contained"
                  href="https://secureform.seamlessdocs.com/f/6dd91f5ce33a8fe86c4d9fddb8867129?embedded=true"
                  target="_blank"
                >
                  Submit Application
                </a> */}
              <p>
                <i>
                  Thank you for your interest in the Second Chance program. The
                  application period is now closed. Applicants will be notified
                  once the selection process has been completed.
                </i>
              </p>
            </div>
          </>
        )}
        <div className="sc__section close-sfs-slider close-sc-slider">
          <ImageMeta
            cloudName="nuvolum"
            publicId="OSG/DEV/osg-share-22-2"
            data-src={`https://res.cloudinary.com/nuvolum/image/upload/v1668717856/OSG/DEV/osg-share-22-2.jpg`}
            responsive
          />
        </div>

        <div className="sc__section">
          <p>
            Follow Nicole’s journey back to a healthy and confident smile on{" "}
            <a
              href="https://www.facebook.com/NJoralsurgerygroup/"
              target="_blank"
              title="Follow us on Facebook"
            >
              Facebook
            </a>{" "}
            and{" "}
            <a
              href="https://www.instagram.com/oralsurgerygroup/"
              target="_blank"
              title="Follow us on Instagram"
            >
              Instagram
            </a>
            !
          </p>
          <p>
            <i>
              Learn more about{" "}
              <Link
                to="/procedure/dental-implants-new-brunswick-nj/"
                title="Learn more about dental implants"
              >
                dental implants
              </Link>{" "}
              and{" "}
              <Link
                to="/procedure/full-arch-restoration-new-brunswick-nj/"
                title="Learn more about full-arch restoration"
              >
                full-arch restoration
              </Link>{" "}
              to see how they can benefit your smile. Whether you need to
              replace one tooth or an entire arch of teeth, our team has a
              solution for you.
            </i>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default SecondChance
